<template>
  <div class="my-pop" ref="pop">
    <el-popover
      placement="bottom-start"
      width="100"
      trigger="hover"
      popper-class="tree-pop"
      @show="onShow"
      @hide="onHide"
    >
      <div class="pop">
        <ul>
          <li
            @click="onAddChildNode"
            v-if="
              currentType !== 2 &&
              $store.state.menu.nowMenuList.indexOf('添加子菜单') >= 0
            "
          >
            添加子菜单
          </li>
          <li
            @click="onModifyNode"
            v-if="
              currentLevel !== 0 &&
              $store.state.menu.nowMenuList.indexOf('修改菜单') >= 0
            "
          >
            修改菜单
          </li>
          <li
            @click="onDeleteNode"
            v-if="
              currentLevel !== 0 &&
              $store.state.menu.nowMenuList.indexOf('删除菜单') >= 0
            "
          >
            删除菜单
          </li>
        </ul>
      </div>
      <i
        class="iconfont its_icon_more02 modify"
        slot="reference"
        ref="icon"
      ></i>
    </el-popover>

    <el-dialog
      :title="title"
      :visible.sync="addDialogVisible"
      append-to-body
      width="30%"
      custom-class="add-menu"
      @close="onDiaClose"
    >
      <div>
        <div class="body">
          <el-form
            :model="addForm"
            :rules="rule"
            ref="addForm"
            label-width="82px"
            label-position="left"
            class="demo-form dialog-form"
          >
            <el-form-item label="上级节点：" prop="parentLabel">
              <el-input v-model.trim="addForm.parentLabel" disabled></el-input>
            </el-form-item>
            <el-form-item label="节点名称：" prop="name">
              <el-input
                v-model.trim="addForm.name"
                placeholder="请输入节点名称"
              ></el-input>
            </el-form-item>
            <el-form-item label="节点类型：" prop="type">
              <el-select
                v-model="addForm.type"
                placeholder="请选择"
                :disabled="
                  title === '修改菜单' || addForm.parentLabel === '平台'
                "
              >
                <el-option label="系统" :value="0"></el-option>
                <el-option label="菜单" :value="1"></el-option>
                <el-option label="按钮" :value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="访问路径：" prop="url">
              <el-input
                v-model.trim="addForm.url"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <el-form-item label="后端路径：" prop="backEndUrls">
              <el-input
                v-model.trim="addForm.backEndUrls"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <el-form-item label="icon：" prop="iconUrl">
              <el-input
                v-model.trim="addForm.iconUrl"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <el-form-item label="排序号：" prop="sortOrder">
              <el-input
                v-model.number.trim="addForm.sortOrder"
                placeholder="请输入排序号"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" size="small" @click="addDialogVisible = false"
          >取消</el-button
        >
        <el-button
          type="primary"
          size="small"
          @click="onSave"
          :loading="editLoading"
          >保存</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { checkNodeName, checkUrl } from '@/common/utils/index.js'
import { insertMenu, updateMenu, deleteMenu } from '@/api/lib/api.js'

export default {
  name: 'pop',
  data() {
    var checkRangeNumber = (rule, value, callback) => {
      if (value === '') {
        callback()
      } else {
        if (typeof value !== 'number' || value < 0 || value > 99) {
          callback(new Error('请输入0-99的整数'))
        } else {
          callback()
        }
      }
    }
    return {
      currentId: '',
      currentName: '',
      currentLevel: NaN,
      addForm: {
        parentId: '',
        parentLabel: '',
        id: '',
        name: '',
        type: '',
        url: '',
        backEndUrls: '',
        iconUrl: '',
        sortOrder: ''
      },
      currentType: '',
      addDialogVisible: false,
      title: '',
      rule: {
        name: [
          { required: true, message: '请输入节点名称', trigger: 'blur' },
          { validator: checkNodeName, trigger: 'blur' }
        ],
        type: [
          { required: true, message: '请选择节点类型', trigger: 'change' }
        ],
        url: [
          { required: true, message: '请输入访问路径', trigger: 'blur' },
          { validator: checkUrl, trigger: 'blur' }
        ],
        sortOrder: [
          { required: true, message: '请输入排序号', trigger: 'blur' },
          { validator: checkRangeNumber, trigger: 'change' }
        ]
      },
      innerText: null,
      editLoading: false
    }
  },
  methods: {
    onShow() {
      this.$refs.icon.classList.add('active')
      const data = JSON.parse(this.$refs.pop.parentNode.children[0].innerText)
      this.currentId = data.id
      this.addForm.parent = this.currentName = data.menuName
      this.currentLevel = data.level
      this.currentType = data.type
      this.innerText = data
    },
    onHide() {
      this.$refs.icon.classList.remove('active')
    },
    onAddChildNode() {
      this.title = '新增子菜单'
      this.addDialogVisible = true
      this.addForm = {
        parentId: this.innerText.id,
        parentLabel: this.innerText.menuName,
        id: '',
        name: '',
        url: '',
        iconUrl: '',
        backEndUrls: '',
        sortOrder: ''
      }
      if (this.innerText.id === 0) {
        this.addForm.type = 0
      }
    },
    onModifyNode() {
      this.title = '修改菜单'
      this.addForm = {
        parentId: this.innerText.parentId,
        parentLabel: this.innerText.parentLabel,
        id: this.innerText.id,
        name: this.innerText.menuName,
        type: this.innerText.type,
        url: this.innerText.url,
        backEndUrls: this.innerText.backEndUrls.join(),
        iconUrl: this.innerText.icon,
        sortOrder: this.innerText.sortOrder
      }
      this.addDialogVisible = true
    },
    onDeleteNode() {
      const h = this.$createElement
      this.$msgbox({
        title: '消息',
        type: 'warning',
        message: h('p', null, [
          h('span', null, '您将删除 '),
          h('span', { style: 'color: #059370' }, this.currentName),
          h('span', null, ' 菜单相关内容，是否删除？')
        ]),
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(() => {
          deleteMenu(this.innerText.id).then((res) => {
            if (res.code === 1000) {
              this.$message({
                type: 'success',
                showClose: true,
                message: '删除成功!'
              })
              this.$store.dispatch('load_MenuTree', {
                parentId: this.innerText.parentId,
                time: new Date().getTime()
              })
            } else {
              this.$message({
                type: 'error',
                showClose: true,
                message: res.msg
              })
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            showClose: true,
            message: '已取消删除'
          })
        })
    },
    // 新增/修改菜单
    onSave() {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          this.editLoading = true
          const data = {
            menuName: this.addForm.name,
            parentId: this.currentId,
            sortOrder: this.addForm.sortOrder,
            url: this.addForm.url,
            backEndUrls: this.addForm.backEndUrls.split(','),
            icon: this.addForm.iconUrl,
            type: this.addForm.type
          }

          if (this.title.indexOf('新增') !== -1) {
            insertMenu(data).then((res) => {
              if (res.code === 1000) {
                this.$message({
                  type: 'success',
                  showClose: true,
                  message: '新增成功'
                })
                this.addDialogVisible = false
                this.$store.dispatch('load_MenuTree', {
                  parentId: this.innerText.id,
                  time: new Date().getTime()
                })
              } else {
                this.$message({
                  type: 'error',
                  showClose: true,
                  message: res.msg
                })
              }
              this.editLoading = false
            })
          } else {
            data.id = this.innerText.id
            data.parentId = this.innerText.parentId
            updateMenu(data).then((res) => {
              if (res.code === 1000) {
                this.$message({
                  type: 'success',
                  showClose: true,
                  message: '修改成功'
                })
                this.addDialogVisible = false
                this.$store.dispatch('load_MenuTree', {
                  parentId: this.innerText.parentId,
                  time: new Date().getTime()
                })
              } else {
                this.$message({
                  type: 'error',
                  showClose: true,
                  message: res.msg
                })
              }
              this.editLoading = false
            })
          }
        }
      })
    },
    onDiaClose() {
      this.$refs.addForm.resetFields()
    }
  },
  created() {}
}
</script>

<style lang="scss" scoped>
.my-pop {
  display: inline-block;
  @include themify() {
    .modify {
      color: themed('n6');
      margin-left: 8px;
      &.active {
        color: themed('b4');
      }
    }
  }
}
</style>

<template>
  <div class="menu-management">
    <div class="left">
      <el-tree
        :props="data1"
        node-key="id"
        :load="loadNode"
        ref="menuTree"
        lazy
        :render-content="renderContent"
        :default-expanded-keys="[0]"
      ></el-tree>
    </div>
    <div class="right"></div>
  </div>
</template>

<script>
import myPop from './components/pop.vue'
import { queryMenu } from '@/api/lib/api.js'

export default {
  name: 'menuManagement',
  components: { myPop },
  data() {
    return {
      data: [
        {
          parentId: '',
          parentLabel: '',
          id: 0,
          level: 0,
          label: '平台',
          children: [
            {
              parentId: 0,
              parentLabel: '平台',
              id: 1,
              level: 1,
              label: '一级 1',
              children: [
                {
                  parentId: 1,
                  parentLabel: '一级 1',
                  level: 2,
                  id: 2,
                  label: '二级 1-1'
                }
              ]
            }
          ]
        }
      ],
      data1: {
        id: 'id',
        label: 'menuName',
        level: 'level',
        parentId: 'parentId',
        parentLabel: 'parentLabel',
        children: 'children',
        isLeaf: 'leaf'
      },
      defaultProps: {
        children: 'children',
        label: 'label'
      }
    }
  },
  methods: {
    handleNodeClick(data) {
      console.log(data)
    },
    renderContent(node, data, store) {
      return (
        <span>
          {data.data.menuName}
          <span class="hidden">{JSON.stringify(data.data)}</span>
          <my-pop />
        </span>
      )
    },
    loadNode(node, resolve) {
      if (node.level === 0) {
        return resolve([
          {
            menuName: '平台',
            id: 0,
            parentId: '',
            parentLabel: '',
            type: 0,
            level: node.level
          }
        ])
      } else if (node.level === 1) {
        queryMenu({ id: 0 }).then((res) => {
          if (res.code === 1000) {
            if (res.data && res.data.length !== 0) {
              res.data.forEach((item) => {
                item.parentLabel = '平台'
                item.level = 2
                item.leaf = item.type !== 0
              })
              return resolve(res.data)
            } else {
              return resolve([])
            }
          } else {
            this.$message.error(res.msg)
          }
        })
      } else {
        queryMenu({ id: node.data.id }).then((res) => {
          if (res.code === 1000) {
            if (res.data && res.data.length !== 0) {
              res.data.forEach((item) => {
                item.parentLabel = node.data.menuName
                item.level = 3
                item.leaf = item.type === 2
              })
              return resolve(res.data)
            } else {
              return resolve([])
            }
          } else {
            this.$message.error(res.msg)
          }
        })
      }
    }
  },
  created() {},
  mounted() {},
  watch: {
    getshowIndex: function (newval, oldval) {
      // 通过节点id找到对应树节点对象
      const node = this.$refs.menuTree.getNode(newval.parentId)
      node.loaded = false
      // 主动调用展开节点方法，重新查询该节点下的所有子节点
      node.expand()
    }
  },
  computed: {
    getshowIndex() {
      return this.$store.state.menu.loadMenuList
    }
  }
}
</script>

<style lang="scss" scoped>
.menu-management {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 16px 20px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: nowrap;
  @include themify() {
    .left {
      flex: 1;
      background-color: themed('n1');
      padding: 20px;
      overflow: auto;
    }
    .right {
      flex: 2;
    }
  }
}

.hidden {
  display: none;
}

.theme-project-resourse {
  .menu-management {
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  }
}
</style>

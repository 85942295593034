var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "pop", staticClass: "my-pop" },
    [
      _c(
        "el-popover",
        {
          attrs: {
            placement: "bottom-start",
            width: "100",
            trigger: "hover",
            "popper-class": "tree-pop"
          },
          on: { show: _vm.onShow, hide: _vm.onHide }
        },
        [
          _c("div", { staticClass: "pop" }, [
            _c("ul", [
              _vm.currentType !== 2 &&
              _vm.$store.state.menu.nowMenuList.indexOf("添加子菜单") >= 0
                ? _c("li", { on: { click: _vm.onAddChildNode } }, [
                    _vm._v(" 添加子菜单 ")
                  ])
                : _vm._e(),
              _vm.currentLevel !== 0 &&
              _vm.$store.state.menu.nowMenuList.indexOf("修改菜单") >= 0
                ? _c("li", { on: { click: _vm.onModifyNode } }, [
                    _vm._v(" 修改菜单 ")
                  ])
                : _vm._e(),
              _vm.currentLevel !== 0 &&
              _vm.$store.state.menu.nowMenuList.indexOf("删除菜单") >= 0
                ? _c("li", { on: { click: _vm.onDeleteNode } }, [
                    _vm._v(" 删除菜单 ")
                  ])
                : _vm._e()
            ])
          ]),
          _c("i", {
            ref: "icon",
            staticClass: "iconfont its_icon_more02 modify",
            attrs: { slot: "reference" },
            slot: "reference"
          })
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            visible: _vm.addDialogVisible,
            "append-to-body": "",
            width: "30%",
            "custom-class": "add-menu"
          },
          on: {
            "update:visible": function($event) {
              _vm.addDialogVisible = $event
            },
            close: _vm.onDiaClose
          }
        },
        [
          _c("div", [
            _c(
              "div",
              { staticClass: "body" },
              [
                _c(
                  "el-form",
                  {
                    ref: "addForm",
                    staticClass: "demo-form dialog-form",
                    attrs: {
                      model: _vm.addForm,
                      rules: _vm.rule,
                      "label-width": "82px",
                      "label-position": "left"
                    }
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "上级节点：", prop: "parentLabel" } },
                      [
                        _c("el-input", {
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.addForm.parentLabel,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.addForm,
                                "parentLabel",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "addForm.parentLabel"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "节点名称：", prop: "name" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入节点名称" },
                          model: {
                            value: _vm.addForm.name,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.addForm,
                                "name",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "addForm.name"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "节点类型：", prop: "type" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              placeholder: "请选择",
                              disabled:
                                _vm.title === "修改菜单" ||
                                _vm.addForm.parentLabel === "平台"
                            },
                            model: {
                              value: _vm.addForm.type,
                              callback: function($$v) {
                                _vm.$set(_vm.addForm, "type", $$v)
                              },
                              expression: "addForm.type"
                            }
                          },
                          [
                            _c("el-option", {
                              attrs: { label: "系统", value: 0 }
                            }),
                            _c("el-option", {
                              attrs: { label: "菜单", value: 1 }
                            }),
                            _c("el-option", {
                              attrs: { label: "按钮", value: 2 }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "访问路径：", prop: "url" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入" },
                          model: {
                            value: _vm.addForm.url,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.addForm,
                                "url",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "addForm.url"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "后端路径：", prop: "backEndUrls" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入" },
                          model: {
                            value: _vm.addForm.backEndUrls,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.addForm,
                                "backEndUrls",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "addForm.backEndUrls"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "icon：", prop: "iconUrl" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入" },
                          model: {
                            value: _vm.addForm.iconUrl,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.addForm,
                                "iconUrl",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "addForm.iconUrl"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "排序号：", prop: "sortOrder" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入排序号" },
                          model: {
                            value: _vm.addForm.sortOrder,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.addForm,
                                "sortOrder",
                                _vm._n(
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              )
                            },
                            expression: "addForm.sortOrder"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "default", size: "small" },
                  on: {
                    click: function($event) {
                      _vm.addDialogVisible = false
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "small",
                    loading: _vm.editLoading
                  },
                  on: { click: _vm.onSave }
                },
                [_vm._v("保存")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "menu-management" }, [
    _c(
      "div",
      { staticClass: "left" },
      [
        _c("el-tree", {
          ref: "menuTree",
          attrs: {
            props: _vm.data1,
            "node-key": "id",
            load: _vm.loadNode,
            lazy: "",
            "render-content": _vm.renderContent,
            "default-expanded-keys": [0]
          }
        })
      ],
      1
    ),
    _c("div", { staticClass: "right" })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }